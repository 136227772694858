exports.components = {
  "component---src-components-blog-blog-post-js": () => import("./../../../src/components/Blog/BlogPost.js" /* webpackChunkName: "component---src-components-blog-blog-post-js" */),
  "component---src-components-templates-template-js": () => import("./../../../src/components/Templates/Template.js" /* webpackChunkName: "component---src-components-templates-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-atgarder-js": () => import("./../../../src/pages/atgarder.js" /* webpackChunkName: "component---src-pages-atgarder-js" */),
  "component---src-pages-forebygga-js": () => import("./../../../src/pages/forebygga.js" /* webpackChunkName: "component---src-pages-forebygga-js" */),
  "component---src-pages-identifiering-js": () => import("./../../../src/pages/identifiering.js" /* webpackChunkName: "component---src-pages-identifiering-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kunskapsbank-js": () => import("./../../../src/pages/kunskapsbank.js" /* webpackChunkName: "component---src-pages-kunskapsbank-js" */),
  "component---src-pages-mallar-js": () => import("./../../../src/pages/mallar.js" /* webpackChunkName: "component---src-pages-mallar-js" */),
  "component---src-pages-om-js": () => import("./../../../src/pages/om.js" /* webpackChunkName: "component---src-pages-om-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-rapportering-js": () => import("./../../../src/pages/rapportering.js" /* webpackChunkName: "component---src-pages-rapportering-js" */)
}

